import RestApi, {
    commonServiceBaseUrl,
    trainingElearningServiceBaseUrl
} from '../config/api_config'

export default {
  computed: {
  },
  watch: {
  },
  created () {
    this.setOrgAndOrgComponentList()
    // this.loadDropdownCommonConfig()
    // const hasDropdownLoadedAgriService = this.$store.state.agriObj.hasDropdownLoaded
    // if (!hasDropdownLoadedAgriService || window.performance) {
    //   this.loadDropdownCommonConfig()
    // }
    this.loadDropdownCommonConfig()
  },
  methods: {
    loadDropdownCommonConfig () {
      RestApi.getData(trainingElearningServiceBaseUrl, 'portal-common-dropdowns', null).then(response => {
        if (response.success) {
          this.$store.commit('Portal/mutateTrainDropdown', {
            hasDropdownLoaded: true,
            trainingTypeList: response.data.trainingTypeList,
            trainingCategoryList: response.data.trainingCategoryList,
            trainingTitleList: response.data.TrainingTitleList,
            duplicateCertificateFeeList: response.data.duplicateCertificateFeeList,
            guestHouseList: response.data.guestHouseList,
            roomTypeList: response.data.roomTypeList,
            roomRentList: response.data.roomRentList,
            roomTypeSetupList: response.data.roomTypeSetupList,
            roomRentSetupList: response.data.roomRentSetupList,
            gradeSetupList: response.data.gradeSetupList
          })
          this.$store.dispatch('Portal/localizeTrainDropdown', { value: this.$i18n.locale })
        }
      })
    },
    setOrgAndOrgComponentList () {
        RestApi.getData(commonServiceBaseUrl, 'common/org-and-org-component-list').then(response => {
          if (response.success === true) {
            const tmpData = response.data.orgList.filter(item => {
                if (item.status !== 1) {
                    const tmp = this.$i18n.locale === 'en' ? { text: item.text_en } : { text: item.text_bn }
                    return Object.assign({}, item, tmp)
                }
            })
            this.mixinSetOrganizationProfileList(10, response.data.orgComponentList, tmpData)
          }
        })
    },
    mixinSetOrganizationProfileList (mixinComponentId, orgComponentList, tmpData) {
        const orgComponents = orgComponentList.filter(item => item.component_id === mixinComponentId)
        const orgCompList = orgComponents.map(item => {
            const orgObj = tmpData.find(org => org.value === item.org_id)
            return this.$i18n.locale === 'en' ? Object.assign({}, orgObj, { text: orgObj.text_en }) : Object.assign({}, orgObj, { text: orgObj.text_bn })
        })
        this.$store.commit('Portal/mutateCommonDropdown', {
            hasDropdownLoaded: true,
            organizationProfileList: orgCompList
        })
    }
  }
}
